const dataKel = [
  { name: "Sukabumi" },
  { name: "Sukabumi Indah" },
  { name: "Campang Raya" },
  { name: "Nusantara Permai" },
  { name: "Campang Jaya" },
  { name: "Way Gubak" },
  { name: "Way Laga" },

  { name: "Srengsem" },
  { name: "Karang Maritim" },
  { name: "Panjang Utara" },
  { name: "Panjang Selatan" },
  { name: "Pidada" },
  { name: "Way Lunik" },
  { name: "Ketapang" },
  { name: "Ketapang Kuala" },

  { name: "Enggal" },
  { name: "Pelita" },
  { name: "Tanjung Karang" },
  { name: "Gunung Sari" },
  { name: "Rawa Laut" },
  { name: "Pahoman" },

  { name: "Durian Payung" },
  { name: "Gotong Royong" },
  { name: "Kaliawi" },
  { name: "Kaliawi Persada" },
  { name: "Kelapa Tiga" },
  { name: "Palapa" },
  { name: "Pasir Gintung" },

  { name: "Labuan Dalam" },
  { name: "Pematang Wangi" },
  { name: "Perumnas Way Kandis" },
  { name: "Tanjung Senang" },
  { name: "Way Kandis" },

  { name: "Kota Karang" },
  { name: "Kota Karang Raya" },
  { name: "Perwata" },
  { name: "Keteguhan" },
  { name: "Sukamaju" },
  { name: "Way Tataan" },

  { name: "Bakung" },
  { name: "Batu Putuk" },
  { name: "Kuripan" },
  { name: "Negeri Olok Gading" },
  { name: "Sukarame II" },

  { name: "Gedong Pakuan" },
  { name: "Gunung Mas" },
  { name: "Pesawahan" },
  { name: "Sumur Putri" },
  { name: "Talang" },
  { name: "Teluk Betung" },

  { name: "Gulak Galik" },
  { name: "Kupang Kota" },
  { name: "Kupang Raya" },
  { name: "Kupang Teba" },
  { name: "Pengajaran" },
  { name: "Sumur Batu" },

  { name: "Gedong Air" },
  { name: "Kelapa Tiga Permai" },
  { name: "Segala Minder" },
  { name: "Sukadanaham" },
  { name: "Sukajawa" },
  { name: "Sukajawa Baru" },
  { name: "Susunan Baru" },

  { name: "Langkapura" },
  { name: "Langkapura Baru" },
  { name: "Gunung Terang" },
  { name: "Gunung Agung" },
  { name: "Bilabong Jaya" },

  { name: "Kebon Jeruk" },
  { name: "Kota Baru" },
  { name: "Sawah Brebes" },
  { name: "Sawah Lama" },
  { name: "Tanjung Agung" },

  { name: "Bumi Waras" },
  { name: "Sukaraja" },
  { name: "Garuntang" },
  { name: "Bumi Raya" },
  { name: "Kangkung" },

  { name: "Kedaton" },
  { name: "Penengahan" },
  { name: "Penengahan Raya" },
  { name: "Sidodadi" },
  { name: "Sukamenanti" },
  { name: "Sukamenanti Baru"},
  { name: "Surabaya"},
  { name: "Kedamaian" },
  { name: "Bumi Kedamaian" },
  { name: "Tanjung Agung Raya" },
  { name: "Tanjung Baru" },
  { name: "Kalibalau Kencana" },
  { name: "Tanjung Raya" },
  { name: "Tanjung Gading" },

  { name: "Beringin Raya" },
  { name: "Kedaung" },
  { name: "Kemiling Permai" },
  { name: "Pinang Jaya" },
  { name: "Sumber Rejo" },
  { name: "Beringin Jaya" },
  { name: "Kemiling Raya" },
  { name: "Sumber Rejo Sejahtera" },

  { name: "Gedong Meneng" },
  { name: "Gedong Meneng Baru" },
  { name: "Rajabasa" },
  { name: "Rajabasa Jaya" },
  { name: "Rajabasa Nunyai" },
  { name: "Rajabasa Pemuka" },
  { name: "Rajabasa Raya" },

  { name: "Korpri Jaya" },
  { name: "Korpri Raya" },
  { name: "Sukarame" },
  { name: "Sukarame Baru" },
  { name: "Way Dadi" },
  { name: "Way Dadi Baru" },

  { name: "Jagabaya 1" },
  { name: "Jagabaya 2" },
  { name: "Jagabaya 3" },
  { name: "Gunung Sulah" },
  { name: "Way Halim Permai" },
  { name: "Perumnas Way Walim" },

  {name: "Kampung Baru Raya"},
  {name: "Labuhan Ratu"},
  {name: "Labuhan Ratu Raya"},
  {name: "Sepang Jaya"},
  {name: "Kota Sepang"},
  {name: "Kampung Baru"},
  {name: "Sumber Agung"},
  {name: "Kemiling Raya"},
];

export default dataKel;
