const dataArray_kecamatan = [
  { name: "Sukabumi" },
  { name: "Panjang" },
  { name: "Labuan Batu" },
  { name: "Tanjung Seneng" },
  { name: "Teluk Betung Selatan" },
  { name: "Teluk Betung Timur" },
  { name: "Teluk Betung Barat" },
  { name: "Teluk Betung Utara" },
  { name: "Enggal" },
  { name: "Tanjung Karang Pusat" },
  { name: "Tanjung Karang Timur" },
  { name: "Bumi Waras" },
  { name: "Tanjung Karang Barat" },
  { name: "Langkapura" },
  { name: "Kemiling" },
  { name: "Rajabasa" },
  { name: "Kedaton" },
  { name: "Kedamaian" },
  { name: "Way Halim" },
  { name: "Sukarame" },
];

export default dataArray_kecamatan;
